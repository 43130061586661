import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="recommendations-step-4"
export default class extends Controller {
  static targets = [
    "excludedCategoriesContainer",
    "form",
    "startYear",
    "endYear"
  ]

  static values = {
    excludedCategories: { type: Array, default: [] },
    initialExcludedCategories: { type: Array, default: [] }
  }

  connect() {
    console.log("RecommendationsStep4Controller connected")
    
    // Initialize with any existing values
    this.initializeExistingValues()
    
    // Initialize autocomplete handlers
    this.initializeAutocompletes()
    
    // Initialize form validation
    this.initializeFormValidation()
    
    // Initialize year range validation
    this.validateYearRange()
  }

  initializeExistingValues() {
    // Set initial values from data attributes
    if (this.initialExcludedCategoriesValue.length > 0) {
      this.excludedCategoriesValue = this.initialExcludedCategoriesValue
      this.renderExcludedCategories()
    }
  }

  initializeAutocompletes() {
    // The autocomplete initialization is handled by the separate JS files
    // We just need to set up the selection handlers
    document.addEventListener('categorySelected', this.handleCategorySelection.bind(this))
  }

  handleCategorySelection(event) {
    const { selection, inputElement } = event.detail
    const category = selection.value
    
    // Determine which container this selection belongs to
    if (inputElement.id === 'excluded-categories') {
      this.addExcludedCategory(category)
    }
  }

  addExcludedCategory(category) {
    if (!this.excludedCategoriesValue.find(c => c.id === category.id)) {
      this.excludedCategoriesValue = [...this.excludedCategoriesValue, category]
      this.renderExcludedCategories()
    }
  }

  removeExcludedCategory(event) {
    const categoryId = parseInt(event.currentTarget.dataset.categoryId)
    this.excludedCategoriesValue = this.excludedCategoriesValue.filter(c => c.id !== categoryId)
    this.renderExcludedCategories()
  }

  renderSelectedItems(items, containerId, removeAction, idField) {
    const container = this[`${containerId}Target`]
    container.innerHTML = items.map(item => `
      <span class="badge bg-primary me-2 mb-2">
        ${item.name}
        <button type="button" class="btn-close btn-close-white ms-2" 
          data-action="click->${removeAction}" 
          data-${idField}="${item.id}">
        </button>
      </span>
    `).join('')
  }

  renderExcludedCategories() {
    this.renderSelectedItems(
      this.excludedCategoriesValue, 
      'excludedCategoriesContainer',
      'recommendations-step-4#removeExcludedCategory',
      'category-id'
    )
  }

  submit(event) {
    event.preventDefault()
    
    // Create a new FormData object from the form
    const form = this.formTarget
    const formData = new FormData(form)
    
    // Remove any existing array fields to prevent duplicates
    form.querySelectorAll('input[name$="[excluded_category_ids][]"]').forEach(el => el.remove())
    
    // Add hidden fields for each selected category
    this.excludedCategoriesValue.forEach(category => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'recommendation_config[excluded_category_ids][]'
      input.value = category.id
      form.appendChild(input)
    })
    
    // Submit the form
    form.submit()
  }
  
  initializeFormValidation() {
    // Add validation class to the form
    this.formTarget.classList.add('needs-validation')
    
    // Add submit event listener for validation
    this.formTarget.addEventListener('submit', (event) => {
      if (!this.formTarget.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }
      
      this.formTarget.classList.add('was-validated')
    })
  }

  validateYearRange() {
    if (!this.hasStartYearTarget || !this.hasEndYearTarget) return

    const startYear = parseInt(this.startYearTarget.value) || null
    const endYear = parseInt(this.endYearTarget.value) || null
    
    // Reset validation state
    this.startYearTarget.setCustomValidity("")
    this.endYearTarget.setCustomValidity("")
    
    // Both years are empty - valid
    if (startYear === null && endYear === null) {
      return
    }
    
    // Validate individual years are within range
    if (startYear !== null) {
      if (startYear < -5000 || startYear > new Date().getFullYear()) {
        this.startYearTarget.setCustomValidity("Year must be between -5000 and current year")
        this.startYearTarget.reportValidity()
        return
      }
    }
    
    if (endYear !== null) {
      if (endYear < -5000 || endYear > new Date().getFullYear()) {
        this.endYearTarget.setCustomValidity("Year must be between -5000 and current year")
        this.endYearTarget.reportValidity()
        return
      }
    }
    
    // Validate start year is before end year
    if (startYear !== null && endYear !== null && startYear > endYear) {
      this.startYearTarget.setCustomValidity("Start year must be before end year")
      this.endYearTarget.setCustomValidity("End year must be after start year")
      this.startYearTarget.reportValidity()
      this.endYearTarget.reportValidity()
      return
    }
  }
}
