import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="review-star-rating"
export default class extends Controller {
  static values = {
    reviewId: Number,
    bookId: Number,
    rating: Number,
    url: String,
    method: String
  }

  connect() {
    this.updateStars()
  }

  rate(event) {
    const rating = parseInt(event.currentTarget.dataset.rating)
    this.ratingValue = rating
    this.updateStars()
    this.submitForm()
  }

  hover(event) {
    const rating = parseInt(event.currentTarget.dataset.rating)
    this.highlightStars(rating)
  }

  unhover() {
    this.updateStars()
  }

  updateStars() {
    this.highlightStars(this.ratingValue)
  }

  highlightStars(rating) {
    const stars = this.element.querySelectorAll('.star-rating')
    stars.forEach((star, index) => {
      if (index < rating) {
        star.classList.remove('bi-star')
        star.classList.add('bi-star-fill')
      } else {
        star.classList.remove('bi-star-fill')
        star.classList.add('bi-star')
      }
    })
  }

  submitForm() {
    const form = this.element.querySelector('form')
    const ratingInput = form.querySelector('input[name="review[rating]"]')
    ratingInput.value = this.ratingValue
    
    // Submit the form using Turbo
    const submitButton = form.querySelector('input[type="submit"]')
    submitButton.click()
  }
}
